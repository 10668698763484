import React, { useEffect } from 'react';
import { List, Divider, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import Folio from '@/interfaces/Folio';
import FolioListItem from '@/shared/components/FolioListItem';
import usePaginationService, { PaginationResponse, UsePaginationService } from '@/hooks/usePaginationService';
import MetaProp from '@/interfaces/MetaProps';

interface FolioScrollListProps {
  service: (
    skip: number, limit: number, params?: any,
  ) => Promise<PaginationResponse<Folio>>;
  skip?: number,
  limit?: number,
  params?: MetaProp,
  reloadService?: boolean,
}

const FolioScrollList = ({
 service, 
 params, 
 skip, 
 limit, 
 reloadService,
}: FolioScrollListProps) => {
  const { t } = useCustomTranslation();
  const config:{
    skip?: number,
  pageSize?: number,
  params?: MetaProp
  } = {};

  if (limit) {
    config.pageSize = limit;
  }

  if (params) {
    config.params = params;
  }

  if (skip) {
    config.skip = skip;
  }

  const foliosState = usePaginationService<Folio>(
    {
      ...config,
      fetchPage: service,
    },
  );

  useEffect(() => {
    foliosState.load();
  }, [reloadService]);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: '75vh',
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
      {!foliosState.loading && (
        <InfiniteScroll
          dataLength={foliosState.data.length}
          next={() => foliosState.setCurrentPageSize(foliosState.pageSize + 10)}
          hasMore={foliosState.data.length < foliosState.total}
          loader={<Spin style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />}
          endMessage={<Divider plain>{t('hints.no_more_data')}</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            size="small"
            bordered
            dataSource={foliosState.data}
            renderItem={(item: any) => (
              <FolioListItem item={item} />
            )}
          />
        </InfiniteScroll>
      )}
    </div>
  );
};

export default FolioScrollList;

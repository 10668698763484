import React from 'react';
import { 
  Avatar, Button, Dropdown, MenuProps, Space, 
} from 'antd';
import useAuth from '@/hooks/useAuth';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';

const TopbarProfile = () => {
  const auth = useAuth();

  const items: MenuProps['items'] = [
    {
      label: 'Cerrar sesión',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: () => auth?.logout(),
    },
  ];

  return (
    <Dropdown 
      menu={{ items }} 
      placement="bottomRight"
      arrow
      trigger={['click']}
    >
      <Button 
        type="link"
        icon={<Avatar src={auth?.staff?.photoUrl} alt="user avatar" />}
        style={{ color: '#646777' }}
      >
        <Space align="center">
          {auth?.staff?.name}
          <DownOutlined style={{ marginBottom: '6px' }} />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default TopbarProfile;

import React from 'react';
import { 
  Card, Col, Row, Typography, 
} from 'antd';
import Container from '@/shared/components/Container';
import useCustomTranslation from '@/hooks/useCustomTranslation';

const HomePage = () => {
  const { t } = useCustomTranslation();
  const welcomeImg = process.env.REACT_APP_BG_HOME || '/img/default_home.png';

  return (
    <Container>
      <Card style={{ minHeight: '80vh' }}>
        <Row justify="center">
          <Col>
            <Typography.Title level={1}>
              {t('g.welcome')}
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={16} className="text-center" style={{ maxHeight: '73vh' }}>
            <img height="100%" style={{ width: '-webkit-fill-available' }} src={welcomeImg} alt={t('g.welcome')} />
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default HomePage;

import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';

const SupplyOrdersManagerPage = lazy(() => import('@/containers/Views/SupplyOrders/SupplyOrdersManagerPage'));

const SupplyOrdersRouter = (
  <Route path="supply_orders">
    <Route
      index 
      element={(
        <PrivateRoute 
          pageName={EPage.SupplyOrders} 
          permissions={EBasicActions.Find}
        >
          <SupplyOrdersManagerPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default SupplyOrdersRouter;

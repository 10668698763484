import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'bootstrap/dist/css/bootstrap.css';
import '@/scss/app.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { PickingJourneyProvider } from '@/hooks/usePickingJourney';
import { AuthProvider } from '@/hooks/useAuth';
import { config as i18nextConfig } from '../../translations';
import Router from './Router/index';
import ScrollToTop from './ScrollToTop';
import Loader from './Router/Loader';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
i18n.init(i18nextConfig);

const App = () => {
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    setIsLoaded(false);
  }, []);

  return (
    <AuthProvider>
      <PickingJourneyProvider>
        <BrowserRouter>
          <I18nextProvider i18n={i18n}>
            <ScrollToTop>
              {isLoaded && <Loader />}
              <Router />
            </ScrollToTop>
          </I18nextProvider>
        </BrowserRouter>
      </PickingJourneyProvider>
    </AuthProvider>
  );
};

export default App;

import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const MesDevicesPage = lazy(() => import('@/containers/Views/MesDevices/MesDevicesPage'));

const MesDevicesRouter = (
  <Route path="mes_devices">
    <Route
      index 
      element={(
        <PrivateRoute
          pageName={EPage.MesDevices}
          permissions={[EBasicActions.Find, EBasicActions.Get]}
        >
          <MesDevicesPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default MesDevicesRouter;

import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const MesActionsPage = lazy(() => import('@/containers/Views/MesActions/MesActionsPage'));

const MesActionsRouter = (
  <Route path="mes_actions">
    <Route
      index 
      element={(
        <PrivateRoute
          pageName={EPage.MesActions}
          permissions={[EBasicActions.Find, EBasicActions.Get]}
        >
          <MesActionsPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default MesActionsRouter;

import Search from 'antd/lib/input/Search';
import React, { useEffect, useState } from 'react';

interface DebounceSearchProps {
    style?: React.CSSProperties;
    placeholder?: string;
    allowClear?: boolean;
    onSearch?: (value: any) => void;
    onChange?: (value: any) => void;
    timeout?: number;
}

const DebounceSearch = ({
    style,
    placeholder,
    allowClear,
    onSearch,
    onChange,
    timeout = 1000,
}: DebounceSearchProps) => {
    const [query, setQuery] = useState('');

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (onChange) {
                onChange({ target: { value: query } });
            }
        },
            timeout);

        return () => clearTimeout(timeOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
      <Search
        onSearch={onSearch || (() => { })}
        onChange={e => setQuery(e.target.value)}
        style={style}
        placeholder={placeholder}
        allowClear={allowClear}
      />

    );
};

export default DebounceSearch;

import React, { useState, useEffect } from 'react';
import { TablePaginationConfig } from 'antd';

export interface PaginationResponse<T> {
  total: number,
  limit: number,
  skip: number,
  data: T[],
}

export interface PaginationProps {
  skip: number,
  limit: number,
  params?: any,
}

// return service intefrace
export interface UsePaginationService<T> extends PaginationResponse<T> {
  loading: boolean,
  pageSize: number,
  currentPage: number,
  pagination: TablePaginationConfig,
  currentParams: any,
  load: ()=> Promise<void>
  setCurrentParams: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onPageChange: (tableConfig: TablePaginationConfig) => void,
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>,
  setCurrentPageSize: React.Dispatch<React.SetStateAction<number>>,
  setPagination: React.Dispatch<React.SetStateAction<PaginationResponse<T>>>;
}

// hook props
interface UsePaginationServiceProps<T> {
  currentPage?: number; // page skip
  pageSize?: number; // page skip
  params?: any; 
  fetchPage: (skip: number, limit: number, params?: any) => Promise<PaginationResponse<T>>;
}

function usePaginationService<T>({ 
  currentPage, 
  pageSize, 
  params,
  fetchPage, 
}: UsePaginationServiceProps<T>): UsePaginationService<T> {
  const [pagination, setPagination] = useState<PaginationResponse<T>>({ 
    total: 0,
    limit: 0,
    skip: 0,
    data: [],
  });
  const [page, setCurrentPage] = useState<number>(currentPage || 1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSize || 10);
  const [currentParams, setCurrentParams] = useState(params || {});
  const [loading, setLoading] = useState(false);

  async function load() {
    setLoading(true);
    const recordsToSkip = (page - 1) * currentPageSize;

    fetchPage(recordsToSkip, currentPageSize, currentParams)
      .then((res) => {
        setPagination(res);
      })
      .catch((err) => {
        console.error(err);
        setPagination({
          total: 0,
          limit: 0,
          skip: 0,
          data: [],
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleChangePage(
    tableConfig: TablePaginationConfig, 
  ) {
    setCurrentPage(tableConfig.current || 2);
  }

  function handlePageSizeChange(current: number, size: number) {
    setCurrentPageSize(size);
  }

  useEffect(() => {
    load();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, currentPageSize, currentParams]);
  
  return {
    ...pagination,
    data: pagination.data,
    loading,
    pageSize: currentPageSize,
    currentPage: page,
    setCurrentPage,
    setCurrentPageSize,
    currentParams,
    setCurrentParams,
    pagination: {
      total: pagination.total,
      current: page,
      pageSize: currentPageSize,
      onShowSizeChange: handlePageSizeChange,
    },
    onPageChange: handleChangePage,
    setLoading,
    load,
    setPagination,
  };
}

export default usePaginationService;

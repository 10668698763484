import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const MesFamilyTypePage = lazy(() => import('@/containers/Views/MesFamilyType/MesFamilyTypePage'));
const MesFamilyTypeManagerPage = lazy(() => import('@/containers/Views/MesFamilyType/MesFamilyTypeManager'));

const MesAttributesRouter = (
  <Route path="mes_family_types">
    <Route
      index 
      element={(
        <PrivateRoute
          pageName={EPage.MesFamilyTypes}
          permissions={[EBasicActions.Find, EBasicActions.Get]}
        >
          <MesFamilyTypePage />
        </PrivateRoute>
      )} 
    />
    <Route
      path=":familyTypeId"
      element={(
        <PrivateRoute
          pageName={EPage.MesFamilyTypes}
          permissions={[
            EBasicActions.Get,
            EBasicActions.Find,
            EBasicActions.Create,
            EBasicActions.Delete, 
          ]}
        >
          <MesFamilyTypeManagerPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default MesAttributesRouter;

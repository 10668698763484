import React from 'react';
import Container from '@/shared/components/Container';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import i18next from 'i18next';
import { Tag } from 'antd';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { formatMoney } from '@/shared/helpers';
import DotsDropdown from '@/shared/components/DotsDropdown';
import SearchableTable from '@/shared/components/table/components/SearchableTable';
import VisibilityTag from '@/shared/VisibilityTag';
import InventoryProduct from '@/interfaces/InventoryProduct';
import DropdownOption from '@/interfaces/DropdownOption';
import IInventoryProduct from '@/interfaces/IInventoryProduct';
import QuantityUnit from '../QuantityUnit';

interface Props {
  inventoryProducts: (IInventoryProduct | any)[];
  loading: boolean,
  showAvailableAndPending?: boolean;
  showPrice?: boolean;
  showVisibility?: boolean;
  subhead?: string | undefined,
  children?: any,
  pagination?: any,
  expandable?: ExpandableConfig<any>;
  actions?: ((prod: InventoryProduct) => DropdownOption[]);
}

const InventoryProductsList = ({
  inventoryProducts,
  loading,
  showAvailableAndPending = true,
  showPrice = true,
  showVisibility = true,
  subhead,
  children,
  pagination,
  expandable,
  actions,
}: Props) => {
  const { t } = useCustomTranslation();
  const lang = i18next.language;

  const columns = [
    {
      title: t('g.sku'),
      dataIndex: ['sku'],
      render: (text: string) => <Tag>{text}</Tag>,
    },
    {
      title: t('g.product_name'),
      dataIndex: [lang === 'en' ? 'nameEn' : 'nameEs'],
    },
    ...showPrice ? [{
      title: t('g.price'),
      dataIndex: ['unitPrice'],
      render: (value: any) => (<p style={{ textAlign: 'right' }}>{formatMoney(value)}</p>),
    }] : [],
    ...showVisibility ? [{
      title: t('g.visibility'),
      dataIndex: ['visibility'],
      render: (text: string) => (
        <VisibilityTag visibility={text} />
      ),
    }] : [],
    ...showAvailableAndPending ? [{
      title: t('g.available'),
      render: (_: any, value: any) => (
        <p style={{ textAlign: 'right' }}>
          <QuantityUnit 
            quantity={(value.forSale - value.pending) ?? 0}
            unit={{ 
              id: value.unit,
              code: value.unit, 
              labelEn: value.unit,
              labelEs: value.unit,
            }}
          />
        </p>
      ),
    },
    {
      title: t('g.pending'),
      dataIndex: ['pending'],
      render: (_: any, value: any) => (
        <p style={{ textAlign: 'right' }}>
          <QuantityUnit 
            quantity={value.pending ?? 0}
            unit={{ 
              id: value.unit,
              code: value.unit, 
              labelEn: value.unit,
              labelEs: value.unit,
            }}
          />
        </p>
      ),
    }] : [],
    {
      title: t('g.total'),
      render: (_: any, value: any) => (
        <p style={{ textAlign: 'right' }}>
          {value.total ? (
            <QuantityUnit 
              quantity={value.total} 
              unit={{ 
                id: value.unit,
                code: value.unit, 
                labelEn: value.unit,
                labelEs: value.unit,
              }}
            />
            ) : '--'}
        </p>
      ),
    },
    ...actions ? [
      {
        title: t('g.actions'),
        dataIndex: 'id',
        width: 30,
        render: (text: string, value: any) => {
          const dropdownMenu = actions(value);

          return (
            <div className="panel__btns" style={{ top: 20 }}>
              <DotsDropdown dropdownMenu={dropdownMenu} />
            </div>
          );
        },
      },
    ] : [],
  ];
  return (
    <SearchableTable
      subhead={subhead}
      columns={columns}
      data={inventoryProducts}
      loading={loading}
      pagination={pagination}
      disableSearch
      rowKey="productId"
      expandable={expandable}
    // placeholder={t('g.search')}
    // onSearch={(value: any) => {
    //   pagination?.setCurrentPage(1);
    //   pagination?.setCurrentParams(
    //     {
    //       '$or[0][nameEs][$like]': `%${value}%`,
    //       '$or[1][nameEn][$like]': `%${value}%`,
    //       '$or[2][sku][$like]': `%${value}%`,
    //     },
    //   );
    // }}
    >
      {children}
    </SearchableTable>
  );
};


export default InventoryProductsList;


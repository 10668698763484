import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@/hooks/useAuth';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';

const MesStationsPage = lazy(() => import('@/containers/Views/MesStations/MesStationsPage'));

const MesStationsRouter = (
  <Route path="mes_stations">
    <Route
      index 
      element={(
        <PrivateRoute
          pageName={EPage.MesStations}
          permissions={[EBasicActions.Find, EBasicActions.Get]}
        >
          <MesStationsPage />
        </PrivateRoute>
      )} 
    />
  </Route>
);

export default MesStationsRouter;

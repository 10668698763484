import {
    Col, Row, Tag, Typography,
} from 'antd';
import React, { ReactNode } from 'react';

interface CardInfoProps {
    img: ReactNode;
    label: string;
    subLabel: string;
    tags: string[];
    extra?: ReactNode;
}

const CardInfo = ({
    img,
    label,
    subLabel,
    tags,
    extra,
}: CardInfoProps) => (
  <Row gutter={[16, 16]}>
    <Col flex={0}>{img}</Col>
    <Col flex={1}>
      <Row justify="space-between">
        <Col>
          {tags.map(t => <Tag>{t}</Tag>)}
        </Col>
        <Col>
          {extra}
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            {label}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Title level={4} style={{ fontWeight: 400, marginBottom: 0 }}>
            {subLabel}
          </Typography.Title>
        </Col>
      </Row>

    </Col>
  </Row>
);

export default CardInfo;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  EnvironmentFilled, QrcodeOutlined, RightOutlined,
} from '@ant-design/icons';
import {
  Button, Col, Form, Input, Row,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import useCustomTranslation from '@/hooks/useCustomTranslation';
import Rack from '@/interfaces/Rack';
import DebounceSelect from '@/shared/components/form/DebounceSelect';

export interface PickupRackInput {
  rackScanner: string,
  rackId: string,
}

interface PickupJourneyRackInputProps {
  form: FormInstance<PickupRackInput>,
  initValues?: Partial<PickupRackInput>,
  qrRef: React.MutableRefObject<any>;
  title?: string;
  rack?: Rack | null;
  disableNext?: boolean;
  onNext?: () => void;
  onSearchRack: (qrStr: string) => void;
  onSelect: (rack: Rack) => void,
  fetchRacks: (value: string) => Promise<Rack[]>,
  onClear?: () => void,
}

const PickupJourneyRackInput = ({
  form,
  initValues,
  qrRef,
  title,
  rack = null,
  disableNext = false,
  onClear = () => {},
  onNext,
  onSearchRack,
  onSelect,
  fetchRacks,
}: PickupJourneyRackInputProps) => {
  const { t } = useCustomTranslation();
  const [racksState, setRacksState] = useState<Rack[]>([]);

  const INIT_VALUES = {
    rackId: '',
    rackScanner: '',
    ...initValues,
  };

  return (
    <Form
      form={form}
      initialValues={INIT_VALUES}
    >
      <Col span={24}>
        <Row justify="center" style={{ width: '100%' }}>
          <EnvironmentFilled style={{ fontSize: '500%', marginBottom: 20 }} />
        </Row>
        <Row justify="center" style={{ marginBottom: 0 }}>
          <h5>{title ?? t('g.from_which_rack_will_you_take_products?')}</h5>
        </Row>
        <Row justify="center" style={{ width: '100%', marginBottom: 20 }}>
          <Form.Item name="rackScanner" style={{ width: '100%' }}>
            <Input
              size="large"
              ref={qrRef}
              addonBefore={<QrcodeOutlined />}
              // inputMode none: prevent unput for text
              inputMode="none"
              placeholder={t('g.rack')}
              onPressEnter={(v: any) => onSearchRack(v.target.value)}
            />
          </Form.Item>
        </Row>

        <Row justify="center" style={{ width: '100%', marginBottom: 20 }}>
          <Form.Item name="rackId" style={{ width: '100%' }}>
            <DebounceSelect
              style={{ width: '100%' }}
              fetchOptions={fetchRacks}
              initialOptions={rack ? [{
                value: rack.id,
                label: rack.alias,
              }] : undefined}
              refactFunc={(racks: Rack[]) => {
                setRacksState(racks);
                return (
                  racks.map(item => ({
                    value: item.id,
                    label: item.alias,
                  })));
              }}
              onSelect={(value: string) => {
                console.log('onSelect', value);
                const selectedRack = racksState.find(i => i.id === value);
                if (!selectedRack) {
                  return;
                }
                onSelect(selectedRack);
              }}
              onClear={onClear}
            />
          </Form.Item>
        </Row>
        { onNext && (
          <Row justify="end">
            <Button 
              type="primary" 
              onClick={onNext} 
              icon={<RightOutlined />}
              disabled={disableNext}
            >
              {t('g.next')}
            </Button>
          </Row>
        )}
      </Col>
    </Form>
  );
};

export default PickupJourneyRackInput;

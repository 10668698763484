import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import EPage from '@/enums/EPages';
import EBasicActions from '@/enums/EBasicActions';
import { PrivateRoute } from '@/hooks/useAuth';
import NotFound from '@/shared/components/NotFound';

const EntryPlanPage = lazy(() => import('@/containers/Views/EntryPlan/EntryPlansPage'));
const EntryPlanDetailPage = lazy(() => import('@/containers/Views/EntryPlan/EntryPlanDetailPage'));
const EntryPlanProcessPage = lazy(() => import('@/containers/Views/EntryPlan/EntryPlanProcessPage'));

const EntryPlansRouter = (
  <Route path="entry_plans">
    <Route 
      index
      element={(
        <PrivateRoute 
          pageName={EPage.EntryPlans} 
          permissions={EBasicActions.Find}
        >
          <EntryPlanPage />
        </PrivateRoute>
      )}
    />
    <Route
      path="not_found" 
      element={(
        <PrivateRoute 
          pageName={EPage.EntryPlans} 
          permissions={EBasicActions.Find}
        >
          <NotFound scope="delivery" />
        </PrivateRoute>
      )} 
    />
    <Route 
      path=":entryPlanId"
      element={(
        <PrivateRoute 
          pageName={EPage.EntryPlans} 
          permissions={EBasicActions.Get}
        >
          <EntryPlanDetailPage />
        </PrivateRoute>
      )}
    />
    <Route 
      path=":entryPlanId/process"
      element={(
        <PrivateRoute 
          pageName={EPage.ProductInventories} 
          permissions={EBasicActions.Create}
        >
          <EntryPlanProcessPage />
        </PrivateRoute>
      )}
    />
  </Route>
);

export default EntryPlansRouter;

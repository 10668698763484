import React from 'react';
import { Dropdown, MenuProps } from 'antd';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import DropdownOption from '@/interfaces/DropdownOption';



interface Props{
  dropdownMenu: DropdownOption[];
} 

const DotsDropdown = ({ dropdownMenu } : Props) => {
  const items: MenuProps['items'] = dropdownMenu.map((item, key) => ({
    icon: item.icon,
    key,
    label: item.label,
    onClick: item.func,
  }));

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <DotsVerticalIcon style={{ cursor: 'pointer' }} />
    </Dropdown>
  );
};

export default DotsDropdown;

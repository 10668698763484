import Staff, { Authentication, StaffWithToken } from '@interfaces/Staff';
import StaffDetail from '@/interfaces/StaffDetail';
import axiosApiConfig from '@/axiosApiConfig';
import { PaginationResponse } from '@/hooks/usePaginationService';
import PageActions from '@/interfaces/PageActions';
import IEstablishmentUser from '@/interfaces/IEstablishmentUser';
import Warehouse from '@/interfaces/Warehouse';
import IWorker from '@/interfaces/IWorkers';
import { IWorkerCreateDto } from '@/shared/components/forms/WorkerForm';
import IEstablishmentWorker from '@/interfaces/IEstablishmentWorker';
// import { staffSelect } from '@/shared/helpers';

export type scopeType = 'operatives' | 'warehouses' | 'franchises' | 'masterFranchises' | 'global';

const getStaff = async (params?: any): Promise<Staff[]> => {
    const result : any = await axiosApiConfig.get('/users', { params: { ...params, isStaff: 1 } });
    return result.data;
};

export const getWarehouseStaff = async (warehouseId: Warehouse['id'], search: string): Promise<Staff[]> => {
    const result : any = await axiosApiConfig.get('/search_warehouse_staff', { params: { warehouseId, search } });
    return result.data;
};

export const getStaffActions = async (staffId: number): Promise<PageActions> => {
    const result: any = await axiosApiConfig.get('/staff_actions', { params: { staffId } });
    return result.data;
};

export const getStaffById = async (id: number): Promise<Staff> => {
    const result : any = await axiosApiConfig.get(`/users/${id}`);
    return result.data;
};

export async function getStaffDetails(staffId: string | number): Promise<StaffDetail> {
    const result : any = await axiosApiConfig.get(`/staff_details?staffId=${staffId}`);
    return result.data;
}

export const newStaff = async (data:any) => {
    const result : any = await axiosApiConfig.post('/users', data);
    return result.data;
};
export interface StaffRelatedWarehouses {
    id: string;
    alias: string;
    staffId: number;
    isActive: boolean;
    orderLimit: number;
    operativeId: number;
    operativeAlias: string;
}
interface StaffRelatedWarehousesParams {
    staffId: number;
    limit?: number;
    skip?: number;
    [key: string]: any;
}

export async function getStaffRelatedWarehouses({ 
    staffId, 
    limit, 
    skip, 
    ...params 
}: StaffRelatedWarehousesParams): Promise<StaffRelatedWarehouses[]> {
    // const result : any = await axiosApiConfig.get(`/warehouse_staff?pagination=false&staffId[$eq]=${staffId}`);
    // type StaffRelations = {
    //     warehouseId: Warehouse['id'];
    //     staffId: number;
    // }
    // return result.data.map((relation: StaffRelations) => relation.warehouseId.toString());
    // check if limit is a valid prop
    if (limit && limit !== null && limit !== undefined) {
        params.$limit = limit;
    }
    if (skip && skip !== null && skip !== undefined) {
        params.$skip = skip;
    }
    const result = await axiosApiConfig.get('/warehouse_related_staff', { 
        params: { 
            ...params,
            staffId,
        },
    });
    return result.data.data;
}

export const getStaffByUser = async (userId: string | number): Promise<Staff> => {
    const result : any = await axiosApiConfig.get(`/staff${userId}`);
    return result.data;
};

export const editStaff = async (data: any) : Promise<Staff> => {
    const result = await axiosApiConfig.patch(`/staff/${data.id}`, data);
    return result.data;
};

export const authentication = async (data: Authentication) : Promise<StaffWithToken> => {
    const result : any = await axiosApiConfig.post('/authentication', data);
    const resData = {
        accessToken: result.data.accessToken,
        ...result.data.user,
    };

    return resData;
};

export const getStaffRelatedWarehouse = async (params?: any): Promise<Staff[]> => {
    const result : any = await axiosApiConfig.get('/staff_related_warehouse', { params: { ...params } });
    return result.data;
};

export const getStaffRelatedOperative = async (params?: any): Promise<Staff[]> => {
    const result : any = await axiosApiConfig.get('/staff_related_operative', { params: { ...params } });
    return result.data;
};

export const getStaffRelatedFranchise = async (params?: any): Promise<Staff[]> => {
    const result : any = await axiosApiConfig.get('/staff_related_franchise', { params: { ...params } });
    return result.data;
};

// async function getStaff(scope: scopeType, id: string | number): Promise<Staff> {
//     const result : any = await axiosApiConfig.get('/staff', { params: { scope, id } });
//     return result.data.data;
// }

export async function getStaffPaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<Staff>> {
    const result : any = await axiosApiConfig.get('/users', { 
        params: { 
            ...params,
            $skip: skip,
            $limit: limit,
            isStaff: 1,
        },
    });
    return result.data;
}

export async function getStaffRelatedWarehousePaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<Staff>> {
    const result : any = await axiosApiConfig.get('/staff_related_warehouse', { 
        params: { 
            ...params,
            $skip: skip,
            $limit: limit,
            isStaff: 1,
        },
    });
    return result.data;
}

export async function getStaffRelatedOperativePaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<Staff>> {
    const result : any = await axiosApiConfig.get('/staff_related_operative', { 
        params: { 
            ...params,
            $skip: skip,
            $limit: limit,
            isStaff: 1,
        },
    });
    return result.data;
}

export async function getStaffRelatedFranchisePaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<Staff>> {
    const result : any = await axiosApiConfig.get('/staff_related_franchise', { 
        params: { 
            ...params,
            $skip: skip,
            $limit: limit,
            isStaff: 1,
        },
    });
    return result.data;
}

export async function getStaffRelatedEstablishmentPaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<Staff>> {
    const result : any = await axiosApiConfig.get('/staff_related_establishment', { 
        params: { 
            ...params,
            $skip: skip,
            $limit: limit,
            isStaff: 1,
        },
    });
    return result.data;
}

export async function getWorkersRelatedEstablishmentPaginated(
    skip: number, limit: number, params?: any,
): Promise<PaginationResponse<IWorker>> {
    const result : any = await axiosApiConfig.get('/workers_related_establishment', { 
        params: { 
            ...params,
            $skip: skip,
            $limit: limit,
        },
    });
    return result.data;
}

export async function getWorkersRelatedEstablishment(
    params?: any,
): Promise<IWorker[]> {
    const result = await axiosApiConfig.get<IWorker[]>('/workers_related_establishment', { 
        params: { 
            ...params,
        },
    });
    return result.data;
}

export async function getStaffRelatedEstablishment(
    params?: any,
): Promise<Staff[]> {
    const result = await axiosApiConfig.get<Staff[]>('/staff_related_establishment', { 
        params: { 
            ...params,
            isStaff: 1,
        },
    });
    return result.data;
}

export async function getEstablishmentStaff(
    params?: any,
): Promise<IEstablishmentUser[]> {
    const result = await axiosApiConfig.get<IEstablishmentUser[]>('/establishment_users', { 
        params: { 
            ...params,
        },
    });

    return result.data;
}

export async function deleteEstablishmentStaff(
    id: string,
): Promise<void> {
    await axiosApiConfig.delete(`/establishment_users/${id}`);
}

interface ICreateEstablishmentUserDto {
    userId: string;
    establishmentId: string;
}

export async function createEstablishmentStaff(
    data: ICreateEstablishmentUserDto,
): Promise<IEstablishmentUser> {
    const result = await axiosApiConfig.post<IEstablishmentUser>('/establishment_users', data);

    return result.data;
}

export async function createWorker(
    data: Omit<IWorkerCreateDto, 'lastName'>,
): Promise<IWorker> {
    const result = await axiosApiConfig.post<IWorker>('/workers', data);

    return result.data;
}

interface IEstablishmentWorkerCreateDto {
    workerId: string;
    establishmentId: string;
}

export async function createEstablishmentWorker(
    data: IEstablishmentWorkerCreateDto,
): Promise<IEstablishmentWorker> {
    const result = await axiosApiConfig.post<IEstablishmentWorker>('/establishment_workers', data);

    return result.data;
}

export async function getWorkers(
    params?: any,
): Promise<IWorker[]> {
    const result = await axiosApiConfig.get<IWorker[]>('/workers', { 
        params: { 
            ...params,
        },
    });

    return result.data;
}

export const editWorker = async (data: any) : Promise<IWorker> => {
    const result = await axiosApiConfig.patch(`/workers/${data.id}`, data);
    return result.data;
};

export async function getEstablishmentWorkers(
    params?: any,
): Promise<IEstablishmentWorker[]> {
    const result: any = await axiosApiConfig.get('/establishment_workers', {
        params: {
            ...params,
        },
    });

    return result.data;
}

export async function deleteEstablishmentWorker(
    id: string,
): Promise<void> {
    await axiosApiConfig.delete(`/establishment_workers/${id}`);
}

export default getStaff;
